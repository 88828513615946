import LayoutReport from '@/layout/Report.vue'
export default [
  {
    path: '/home',
    component: LayoutReport,
    children: [
      {
        path: 'manage',
        name: 'homeManage',
        component: () => import(/* webpackChunkName: "homeManage" */ '../views/home/Manage.vue'),
        meta: {
          title: '管理首页'
        }
      }

    ]
  }
]
