export default {
  /**
   * 数字格式化，默认格式化为2个小数点 3 --> 3.00
   * @param {Number} num 需要格式的值
   * @param {Number} imum //几位小数
   */
  formatNumber (num, imum = 2) {
    if (num) {
      try {
        num = parseFloat(num)
        return num
          .toLocaleString('zh', {
            style: 'currency',
            currency: 'cny',
            maximumFractionDigits: imum,
            minimumFractionDigits: imum
          })
          .slice(1)
      } catch (error) {
        return num
      }
    } else {
      return 0
    }
  },
  // 超过20位显示...
  ellipsis: function (value) {
    if (!value) return ''
    if (value.length > 30) {
      return value.slice(0, 30) + '...'
    }
    return value
  }
}
