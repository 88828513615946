import LayoutReport from '@/layout/Report.vue'
export default [
  {
    path: '/log',
    component: LayoutReport,
    children: [
      {
        path: 'list',
        name: 'logList',
        component: () => import(/* webpackChunkName: "logList" */ '../views/log/List.vue'),
        meta: {
          title: '日志列表'
        }
      }

    ]
  }
]
