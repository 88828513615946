import LayoutReport from '@/layout/Report.vue'
export default [
  {
    path: '/notice',
    component: LayoutReport,
    children: [
      {
        path: 'list',
        name: 'noticeList',
        component: () => import(/* webpackChunkName: "noticeList" */ '../views/notice/List.vue'),
        meta: {
          title: '消息列表'
        }
      }

    ]
  }
]
