export default {
  /**
     * 用户角色转换
     * 角色：0-高级管理员 1-管理员 2-普通用户 3-临时用户 99-超级管理员
     * @param {Number} roleType 需要格式的值
     */
  umsUserRoleType (roleType) {
    let result = ''

    if (roleType === 0) {
      result = '高级管理员'
    } else if (roleType === 1) {
      result = '普通管理员'
    } else if (roleType === 2) {
      result = '普通用户'
    } else if (roleType === 3) {
      result = '临时用户'
    } else if (roleType === 99) {
      result = '超级管理员'
    }
    return result
  }
}
