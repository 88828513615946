/*
 * @Description:
 * @Author: Pengfei.Wang
 * @Date: 2022-04-27 10:01:50
 * @LastEditors: Pengfei.Wang
 * @LastEditTime: 2022-06-27 15:57:13
 * @version: 1.0
 * Copyright(C)中国软件与技术服务股份有限公司-版权所有
 */
import axios from 'axios'
import store from '../store'
import { MessageBox } from 'element-ui'
// 创建axios实例
const service = axios.create({
  baseURL: `/${process.env.VUE_APP_BACKEND_CONTEXT_PATH}/api`,
  timeout: 120000 // request timeout
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (store.getters['user/token']) {
      config.headers.Authorization = store.getters['user/token']
    }
    if (!sessionStorage.getItem('frontendToken')) {
      sessionStorage.setItem('frontendToken', localStorage.getItem('frontendToken'))
    }
    if ('' + sessionStorage.getItem('frontendToken') === '' + localStorage.getItem('frontendToken')) {
      return config
    } else {
      MessageBox.alert('当前不是最新的用户信息点击确认刷新信息', '', {
        confirmButtonText: '确定',
        showClose: false,
        beforeClose: action => {
          sessionStorage.setItem('frontendToken', localStorage.getItem('frontendToken'))
          location.href = '/'
        }
      })
    }
  },
  (error) => {
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  async (response) => {
    const res = response.data
    if (res.code === 500 && res.message === '无法获取到当前用户') {
      store.dispatch('user/fedLogOut')
      MessageBox.alert('登录过期请重新登录', '', {
        confirmButtonText: '确定',
        showClose: false,
        beforeClose: action => {
          location.href = '/'
        }
      })
      return new Promise(() => { })
    }
    if (res.code === 217) {
      await store.dispatch('app/setClose')
    }
    return res
  },
  (error) => {
    return Promise.reject(error)
  }
)
export default service
