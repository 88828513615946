<template>
  <div
    class="layoutReport"
    v-if="isInit"
    :class="{ collapse: appSidebar.collapse }"
  >
    <Header ref="header" @handleChange="getParam"/>
    <div class="layoutReport-container">
      <Aside class="layoutReport-container__aside" />
      <div class="layoutReport-container__main">
        <keep-alive>
          <router-view
            v-if="$route.meta.keepAlive"
            class="layoutReport-container__main-view"
          />
        </keep-alive>
        <router-view
          v-if="!$route.meta.keepAlive"
          class="layoutReport-container__main-view"
        />
      </div>
    </div>
    <CloseDialog />
    <ReportDialog
      :visible.sync="dialogVisible"
      dialogTitle="修改密码"
      width="649px"
      :before-close="closeDialog"
      :close-on-click-modal="true"
    >
      <ReportForm
        :model="passwordForm"
        label-width="100px"
        :rules="rules"
        ref="passwordForm"
      >
        <div v-if="appPlatform === 'zy' || appPlatform === 'bt'" style="margin-bottom: 22px">注意：仅修改的是本系统中新增账号的密码，不影响OA登录账号密码</div>
        <el-form-item label="原密码" prop="oldPwd">
          <ReportInput v-model="passwordForm.oldPwd" show-word-limit  maxlength="18"></ReportInput>
        </el-form-item>
        <el-form-item label="新密码" prop="pass">
          <ReportInput v-model="passwordForm.pass" maxlength="18" show-word-limit placeholder="请输入8-18位大小写英文、数字、符号组成的密码"></ReportInput>
        </el-form-item>
        <el-form-item label="再次输入" prop="checkPass">
          <ReportInput v-model="passwordForm.checkPass" maxlength="18" show-word-limit></ReportInput>
        </el-form-item>
      </ReportForm>
      <span slot="footer" class="dialog-footer">
        <ReportButton type="primary" @click="closeDialog" plain :disabled = "false"
        >取 消</ReportButton
        >
        <ReportButton
          type="primary"
          @click="updatePwdSubmit('passwordForm')"
          :loading="loading"
        >确 定</ReportButton
        >
      </span>
    </ReportDialog>
  </div>
</template>
<script>
import layoutMixin from './mixin/layoutMixin'
import Header from './components/Header.vue'
import Aside from './components/Aside.vue'
import CloseDialog from './components/CloseDialog'
import { mapGetters } from 'vuex'
import ReportButton from '@/components/report-button'
import ReportDialog from '@/components/report-dialog.vue'
import ReportForm from '@/components/report-form.vue'
import ReportInput from '@/components/report-input'
import { updatePwd } from '@/api/user'
export default {
  name: 'LayoutReport',
  mixins: [layoutMixin],
  components: {
    Header,
    Aside,
    CloseDialog,
    ReportButton,
    ReportDialog,
    ReportForm,
    ReportInput
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.passwordForm.oldPwd === value) {
          callback(new Error('原密码与新密码不可一致'))
        }
        const pwdReg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,18}$/
        if (!pwdReg.test(value)) {
          callback(new Error('密码格式错误，密码必须是包含大写字母、小写字母、数字、特殊符号的8-18位的组合。'))
        }
        if (this.passwordForm.checkPass !== '') {
          this.$refs.passwordForm.$children[0].validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.passwordForm.pass) {
        callback(new Error('两次输入的新密码不一致，请重新输入'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      closeModal: true,
      loading: false,
      disabledParam: false,
      passwordForm: { pass: '', oldPwd: '', checkPass: '' },
      rules: {
        pass: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ],
        oldPwd: [
          { required: true, message: '请输入原密码', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    const defaultPassword = localStorage.getItem('defaultPassword')
    if (defaultPassword === 'true') {
      this.$message.error('请修改您的默认密码')
      this.dialogVisible = true
      this.closeModal = false
      this.disabledParam = true
    }
  },
  methods: {
    closeDialog() {
      // const defaultPassword = localStorage.getItem('defaultPassword')
      // if (defaultPassword === 'false') {
      this.dialogVisible = false
      // }
      this.passwordForm.oldPwd = ''
      this.passwordForm.checkPass = ''
      this.passwordForm.pass = ''
      localStorage.setItem('defaultPassword', null)
    },
    getParam(param) {
      this.dialogVisible = param
    },
    resetForm() {
      this.$refs.passwordForm.$children[0].resetFields()
    },
    async updatePwdSubmit() {
      this.$refs.passwordForm.$children[0].validate(async (valid) => {
        if (valid) {
          const loginType = localStorage.getItem('loginType')
          this.passwordForm.loginType = loginType
          const data = await updatePwd(this.passwordForm)
          if (data.code === 200 && data.data === '修改用户密码成功') {
            this.$message.success(data.data)
            this.dialogVisible = false
            this.resetForm()
            this.$store.dispatch('user/fedLogOut')
            location.reload()
          } else {
            this.$message.error(data.data)
          }
        } else {
          return false
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      appSidebar: 'app/sidebar'
    })
  }
}
</script>

<style lang="scss" scoped>
.layoutReport {
  font-size: 16px;
  min-width: 1180px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: #f2f3f5;
  &-container {
    position: absolute;
    top: 64px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    &__aside {
      height: 100%;
      width: 240px;
      float: left;
    }
    &__main {
      height: 100%;
      margin-left: 252px;
      transition: all 0.25s;
      position: relative;
      &-view {
        height: 100%;
      }
    }
  }
  &.collapse {
    .layoutReport-container {
      &__aside {
        width: 76px;
        padding-right: 0px;
      }
      &__main {
        margin-left: 76px;
      }
    }
  }
}
</style>
<style lang="scss">
html,
body {
  height: 100%;
}
a {
  color: #3370ff;
}

.layoutReport {
  .clearfix {
    &:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
    }
  }
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ellipsis-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  @keyframes loadingSpinnerFadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .el-loading-spinner {
    animation-delay: 500ms;
    animation-duration: 100ms;
    animation-fill-mode: both;
    animation-name: loadingSpinnerFadeIn;
  }

  .el-loading-spinner .circular {
    height: 50px;
    width: 50px;
  }

  .el-loading-mask {
    background-color: rgba(255, 255, 255, 0);
  }
}
</style>
