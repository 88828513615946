import moment from 'moment'
const datetime = {
  /**
   * 格式化时间 2000
   * @param {Date} time
   */
  dateFormatY: function (time) {
    if (time) {
      return moment(time).format('YYYY')
    } else {
      return ''
    }
  },
  /**
   * 格式化时间 2000-11-11
   * @param {Date} time
   */
  dateFormatYMD: function (time) {
    if (time) {
      return moment(time).format('YYYY-MM-DD')
    } else {
      return ''
    }
  },
  /**
   * 格式化时间 2000-11-11
   * @param {Date} time
   */
  dateFormatYMD1: function (time) {
    if (time) {
      return moment(time).format('YYYYMMDD')
    } else {
      return ''
    }
  },
  /**
   * 格式化时间 2000年11月11日
   * @param {Date} time
   */
  dateFormatYMD2: function (time) {
    if (time) {
      return moment(time).format('YYYY年MM月DD日')
    } else {
      return ''
    }
  },
  /**
   * 格式化时间 2000-11-11 11:22:33
   * @param {Date} time
   */
  dateFormatYMDHms: function (time) {
    if (time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    } else {
      return ''
    }
  },
  /**
   * 格式化时间 20001111112233
   * @param {Date} time
   */
  dateFormatYMDHms2: function (time) {
    if (time) {
      return moment(time).format('YYYYMMDDHHmmss')
    } else {
      return ''
    }
  },
  /**
   * 格式化时间 2000-11-11 12:22
   * @param {Date} time
   */
  dateFormatYMDHm: function (time) {
    if (time) {
      return moment(time).format('YYYY-MM-DD HH:mm')
    } else {
      return ''
    }
  },
  /**
   * 格式化时间 11:22:33
   * @param {Date} time
   */
  dateFormatHms: function (time) {
    if (time) {
      return moment(time).format('HH:mm:ss')
    } else {
      return ''
    }
  }
}
export default datetime
