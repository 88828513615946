import LayoutMain from '@/layout/Main.vue'
export default [
  {
    path: '/portal',
    component: LayoutMain,
    children: [
      {
        path: 'home',
        name: 'portalHome',
        component: () => import(/* webpackChunkName: "portalHome" */ '../views/portal/Home.vue'),
        meta: {
          title: '信息快报'
        }
      },
      {
        path: 'login',
        name: 'portalLogin',
        component: () => import(/* webpackChunkName: "portalLogin" */ '../views/login/index.vue'),
        meta: {
          title: '北投数据治理主题门户'
        }
      }

    ]
  }
]
