<template>
  <router-view v-if="isInit" />
</template>
<script>
import layoutMixin from './mixin/layoutMixin'
export default {
  name: 'LayoutMain',
  mixins: [layoutMixin]
}
</script>
<style lang="scss">
html,
body {
  height: 100%;
}
a {
  color: #3370ff;
}

.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@keyframes loadingSpinnerFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.el-loading-spinner {
  animation-delay: 500ms;
  animation-duration: 100ms;
  animation-fill-mode: both;
  animation-name: loadingSpinnerFadeIn;
}

.el-loading-spinner .circular {
  height: 50px;
  width: 50px;
}

.el-loading-mask {
  background-color: rgba(255, 255, 255, 0);
}
</style>
