import axios from '@/utils/axios'

/**
 * 账号密码登录
 * @param {*} params
 */
export function loginValidLogin (params) {
  return axios({
    url: '/login/validLogin',
    method: 'get',
    params
  })
}

/**
 * 获取当前用户详情
 * @param {*} params
 */
export function loginGetCurrentUserInfo (params) {
  return axios({
    url: '/login/getCurrentUserInfo',
    method: 'get',
    params
  })
}

/**
 * 短信验证登录
 * @param {*} params
 */
export function loginUserMsgLogin (params) {
  return axios({
    url: '/login/validMsgLogin',
    method: 'get',
    params
  })
}

/**
 * 发送验证码
 * @param {*} data
 */
export function loginSendValidCode (params) {
  return axios({
    url: '/login/getValidCode',
    method: 'get',
    params
  })
}

/**
 * 获取用户信息列表
 * @param {*} params
 */
export function loginGetUserInfoList (params) {
  return axios({
    url: '/login/getUserInfoList',
    method: 'get',
    params
  })
}

/**
 * 修改用户
 * @param {*} data
 */
export function loginUpdateUserInfo (data) {
  return axios({
    url: '/login/updateUserInfo',
    method: 'post',
    data
  })
}

/**
 * 查询用户
 * @param {*} params
 */
export function userSelectByOrgId (params) {
  return axios({
    url: '/user/selectByOrgId',
    method: 'get',
    params
  })
}

/**
 * 登录
 * @param {*} params
 */
export function userTokenLogin (params) {
  return axios({
    url: '/login/validTokenLogin',
    method: 'get',
    params
  })
}
/**
 * 退出
 * @param {*} data
 */
export function userLogout (data) {
  return axios({
    url: '/user/logout?loginLogId=' + data.loginLogId,
    method: 'get',
    data
  })
}

/**
 * 获取当前用户详情
 * @param {*} params
 */
export function userInfo (params) {
  return axios({
    url: '/login/getCurrentUserInfo',
    method: 'get',
    params
  })
}

/**
 * 根据ID获取用户信息
 * @param {*} params
 */
export function getUserInfoById (params) {
  return axios({
    url: '/login/getUserInfoById',
    method: 'get',
    params
  })
}

/**
 * 获取用户信息列表
 * @param {*} params
 */
export function getUserInfoList (params) {
  return axios({
    url: '/login/getUserInfoList',
    method: 'get',
    params
  })
}

/**
 * 添加用户
 * @param {*} data
 */
export function userInfoAdd (data) {
  return axios({
    url: '/login/addUserInfo',
    method: 'post',
    data
  })
}
/**
 * 更新用户状态
 * @param {*} params
 */
export function updateUserStatus (params) {
  return axios({
    url: '/login/updateUserStatus',
    method: 'get',
    params
  })
}
/**
 * 更新用户状态
 * @param {*} params
 */
export function updateUserPortalRole (params) {
  return axios({
    url: '/login/updateUserPortalRole',
    method: 'get',
    params
  })
}

/**
 * 删除用户
 * @param {*} params
 */
export function userInfoDelete (params) {
  return axios({
    url: '/login/deleteUserById',
    method: 'get',
    params
  })
}

/**
 * 发送验证码
 * @param {*} data
 */
export function sendValidCode (params) {
  return axios({
    url: '/login/getValidCode',
    method: 'get',
    params
  })
}

/**
 * 发送提示信息
 * @param {*} data, params
 */
export function postNotice (data, params) {
  return axios({
    url: '/login/postText',
    method: 'post',
    data,
    params
  })
}

/**
 * 微信扫码登录
 * @param {*} params
 */
export function adminWxLogin (params) {
  return axios({
    url: '/login/wxLogin',
    method: 'get',
    params
  })
}
/**
 * 修改用户密码
 * @param {*} data
 */
export function updatePwd (data) {
  return axios({
    url: '/login/updatePwd',
    method: 'post',
    data
  })
}
/**
 * 查询用户是否重复
 * @param {*} params
 */
export function userIsUnique (data) {
  return axios({
    url: '/user/userIsUnique',
    method: 'post',
    data
  })
}
/**
 * 组织机构逻辑删除
 * @param {*} data
 */
export function userDelete (data) {
  return axios({
    url: '/login/deleteUserByIds',
    method: 'post',
    data
  })
}

/**
 * 组织机构逻辑删除
 * @param {*} data
 */
export function userRest (data) {
  return axios({
    url: '/login/resetUserByIds',
    method: 'post',
    data
  })
}
/**
 * ZY账号密码登录
 * @param {*} params
 */
export function loginZY (params) {
  return axios({
    url: '/login/loginZY',
    method: 'get',
    params
  })
}

/**
 * ZY消息登录
 * @param {*} params
 */
export function msgloginZY (params) {
  return axios({
    url: '/login/msgloginZY',
    method: 'get',
    params
  })
}

/**
 * zzwl单点
 * @param {*} params
 */
export function loginZZWL (params) {
  return axios({
    url: '/login/loginZZWL',
    method: 'get',
    params
  })
}
/**
 * GXJF单点
 * @param {*} params
 */
export function loginGXJF (params) {
  return axios({
    url: '/login/loginGXJF',
    method: 'get',
    params
  })
}
/**
 * GXFLOW单点
 * @param {*} params
 */
export function loginGXFLOW (params) {
  return axios({
    url: '/login/loginGXFLOW',
    method: 'get',
    params
  })
}
