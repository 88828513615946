<template>
  <div class="aside">
    <div class="aside__main animate__animated animate__slideInLeft" style="animation-duration: 500ms">
      <el-menu class="aside-menu" :default-active="defaultActive" text-color="#1F2329" active-text-color="#3370FF" :collapse="appSidebar.collapse" :collapse-transition="false" router>
        <el-menu-item :index="item.url" v-for="(item, index) in asideList" :key="index">
          <i v-if="!item.activeList.includes($route.path)" :class="item.icon"></i>
          <img v-if="item.activeList.includes($route.path)" :src="item.gif" alt="" class="aside-menu__gif" />

          <span slot="title">{{ item.label }}</span>
          <CountTo
            v-if="item.count != 0 && !appSidebar.collapse && appPlatform != 'gx'"
            class="aside__badge"
            :class="{
              'animate__animated animate__bounceInRight': !isInitAside,
              'animate__animated animate__bounce': item.animate
            }"
            :startVal="!isInitAside ? 1 : item.count >= 100 ? 99 : item.count"
            :endVal="item.count >= 100 ? 99 : item.count"
            :suffix="item.count >= 100 ? '+' : ''"
            :duration="3000"
          />
        </el-menu-item>
      </el-menu>
    </div>
    <div class="aside-open" v-show="appSidebar.collapse" @click="handlersetCollapse(false)">
      <i class="icon iconfont ic_a-doubleright"></i>
    </div>
    <div
      class="aside-close"
      :class="{
        'animate__animated animate__fadeIn animate__delay-1s': !isInitAside
      }"
      v-show="!appSidebar.collapse"
      @click="handlersetCollapse(true)"
    >
      <i class="icon iconfont ic_doubleleft"></i>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CountTo from 'vue-count-to'
export default {
  name: 'AsideComponents',
  components: {
    CountTo
  },
  computed: {
    ...mapGetters({
      appPlatform: 'app/platform',
      appSidebar: 'app/sidebar',
      userUserInfo: 'user/userInfo',
      userAsideList: 'user/asideList',
      appBackendContextPath: 'app/backendContextPath',
      appRateTitle: 'app/rateTitle'
    }),
    // 计算选中状态的index
    defaultActive() {
      let result = ''
      for (let i = 0; i < this.userAsideList.length; i++) {
        if (this.userAsideList[i].activeList.includes(this.$route.path)) {
          result = this.userAsideList[i].url
          break
        }
      }
      return result
    }
  },
  data() {
    return {
      timer: null,
      asideList: null,
      isInitAside: false
    }
  },
  async created() {
    // 根据角色赋值不通的list
    this.initList()
    if (this.appPlatform !== 'gx') {
      await this.$store.dispatch('user/setAsideNum')
    }
    // 重新计算asideList;
    this.handlerUserAsideList()
    setTimeout(() => {
      this.isInitAside = true
    }, 5000)

    clearInterval(this.timer)
    this.timer = null
    this.timer = setInterval(() => {
      if (this.appPlatform !== 'gx') {
        this.$store.dispatch('user/setAsideNum')
      }
    }, 1000 * 60)
  },
  watch: {
    $route: function () {
      if (this.appPlatform !== 'gx') {
        this.$store.dispatch('user/setAsideNum')
      }
    },
    userAsideList: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.isInitAside) {
          this.handlerUserAsideList()
        }
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    handlerUserAsideList() {
      if (this.isInitAside) {
        for (let m = 0; m < this.userAsideList.length; m++) {
          // 如果数量发生变化
          if (this.asideList[m].count !== this.userAsideList[m].count) {
            this.asideList[m].count = this.userAsideList[m].count
            this.asideList[m].animate = true
            setTimeout(() => {
              this.asideList[m].animate = false
            }, 1000)
          }
        }
      } else {
        // 如果没有初始化列表 赋值列表
        this.asideList = JSON.parse(JSON.stringify(this.userAsideList))
        let delayTime = 0 // 延迟时间
        // 循环所有列表
        for (let m = 0; m < this.asideList.length; m++) {
          // 初始化所有数量为0, 页面0隐藏
          this.asideList[m].count = 0
          // 如果count大于0，顺序加载，第一个结束后第二个才开始 第一个延迟 0 * 2000， 第二个 1 * 2000， 第三个 2*2000
          if (this.userAsideList[m].count > 0) {
            // 延迟显示
            setTimeout(() => {
              this.asideList[m].count = this.userAsideList[m].count
            }, delayTime)
            delayTime += 200
            // 延迟时间
          }
        }
      }
    },
    initList() {
      const list = [
        {
          icon: 'icon iconfont ic_approval',
          gif: `/${this.appBackendContextPath}/static/libs/img/approval.gif`,
          url: '/reports/approval/list',
          label: '我的审批',
          count: 0,
          animate: false,
          activeList: ['/reports/approval/list', '/reports/approval/details', '/reports/approval/info', '/reports/approval/status', '/reports/approval/update', '/reports/approval/fillInfo']
        },
        {
          icon: 'icon iconfont ic_edit',
          gif: `/${this.appBackendContextPath}/static/libs/img/edit.gif`,
          url: '/reports/mine/list',
          label: '我的填报',
          count: 0,
          animate: false,
          activeList: ['/reports/mine/list', '/reports/mine/info', '/reports/mine/edit', '/reports/mine/update']
        },
        {
          icon: 'icon iconfont ic_smile1',
          gif: `/${this.appBackendContextPath}/static/libs/img/smile.gif`,
          url: '/reports/help/list',
          label: '帮忙填报',
          count: 0,
          animate: false,
          activeList: ['/reports/help/list', '/reports/help/edit']
        },
        {
          icon: 'icon iconfont ic_control',
          gif: `/${this.appBackendContextPath}/static/libs/img/control.gif`,
          url: '/reports/manage/list',
          label: '填报管理',
          count: 0,
          animate: false,
          activeList: ['/reports/manage/list', '/reports/manage/info', '/reports/manage/add', '/reports/manage/details', '/reports/manage/filledInfo', '/reports/status/update', '/reports/manage/fillInfo', '/reports/status/info']
        },
        {
          icon: 'icon iconfont ic_log',
          gif: `/${this.appBackendContextPath}/static/libs/img/log.gif`,
          url: '/log/list',
          label: '日志管理',
          count: 0,
          animate: false,
          activeList: ['/log/list']
        },
        {
          icon: 'icon iconfont ic_message',
          gif: `/${this.appBackendContextPath}/static/libs/img/message.gif`,
          url: '/notice/list',
          label: '消息通知',
          count: 0,
          animate: false,
          activeList: ['/notice/list']
        },
        {
          icon: 'icon iconfont ic_yonghu',
          gif: `/${this.appBackendContextPath}/static/libs/img/user.gif`,
          url: '/user/list',
          label: '用户管理',
          count: 0,
          animate: false,
          activeList: ['/user/list']
        },
        {
          icon: 'icon iconfont ic_detail',
          gif: `/${this.appBackendContextPath}/static/libs/img/path.gif`,
          url: '/reports/config/list',
          label: '填报配置',
          count: 0,
          animate: false,
          activeList: ['/reports/config/list', '/reports/config/info', '/reports/processConfig/info']
        },
        {
          icon: 'icon iconfont ic_project',
          gif: `/${this.appBackendContextPath}/static/libs/img/task.gif`,
          url: '/schedule/list',
          label: '任务信息',
          count: 0,
          animate: false,
          activeList: ['/schedule/list']
        },
        {
          icon: 'icon iconfont ic_setting',
          gif: `/${this.appBackendContextPath}/static/libs/img/set.gif`,
          url: '/about/system',
          label: '关于系统',
          count: 0,
          animate: false,
          activeList: ['/about/system']
        },
        {
          icon: 'icon iconfont ic_calendar-check',
          gif: `/${this.appBackendContextPath}/static/libs/img/check.gif`,
          url: '/reports/processConfig/list',
          label: '流程配置',
          count: 0,
          animate: false,
          activeList: ['/reports/processConfig/list']
        },
        {
          icon: 'icon iconfont ic_home',
          gif: `/${this.appBackendContextPath}/static/libs/img/home.gif`,
          url: '/home/manage',
          label: '首页',
          count: 0,
          animate: false,
          activeList: ['/home/manage']
        },
        {
          icon: 'icon iconfont ic_file-excel',
          gif: `/${this.appBackendContextPath}/static/libs/img/file-excel.gif`,
          url: '/reports/templateFile/list',
          label: '填报模版',
          count: 0,
          animate: false,
          activeList: ['/reports/templateFile/list', '/reports/templateFile/info']
        },
        {
          icon: 'icon iconfont ic_reconciliation',
          gif: `/${this.appBackendContextPath}/static/libs/img/reconciliation.gif`,
          url: '/log/operatorLog/list',
          label: '操作日志',
          count: 0,
          animate: false,
          activeList: ['/log/operatorLog/list']
        },
        {
          icon: 'icon iconfont ic_calendar-check',
          gif: `/${this.appBackendContextPath}/static/libs/img/check.gif`,
          url: '/workflow/bpmn-modeler',
          label: '流程设计器',
          count: 0,
          animate: false,
          activeList: []
        },
        {
          icon: 'icon iconfont ic_calendar-check',
          gif: `/${this.appBackendContextPath}/static/libs/img/check.gif`,
          url: '/workflow/workflowDefinition',
          label: '流程定义',
          count: 0,
          animate: false,
          activeList: []
        },
        {
          icon: 'icon iconfont ic_calendar-check',
          gif: `/${this.appBackendContextPath}/static/libs/img/check.gif`,
          url: '/workflow/workflowDeployment',
          label: '流程部署',
          count: 0,
          animate: false,
          activeList: []
        },
        {
          icon: 'icon iconfont ic_yonghu',
          gif: `/${this.appBackendContextPath}/static/libs/img/user.gif`,
          url: '/organization/list',
          label: '组织机构',
          count: 0,
          animate: false,
          activeList: ['/organization/list']
        },
        {
          icon: 'icon iconfont ic_bank',
          gif: `/${this.appBackendContextPath}/static/libs/img/bank.gif`,
          url: '/reports/dataSubmission/list',
          label: '数据报送',
          count: 0,
          animate: false,
          activeList: ['/reports/dataSubmission/list']
        },
        {
          icon: 'icon iconfont ic_control',
          gif: `/${this.appBackendContextPath}/static/libs/img/control.gif`,
          url: '/reports/riskControlTemplate/list',
          label: '模型管理',
          count: 0,
          animate: false,
          activeList: ['/reports/riskControlTemplate/list', '/reports/riskControlTemplate/edit', '/reports/riskControlTemplate/show']
        },
        {
          icon: 'icon iconfont ic_approval',
          gif: `/${this.appBackendContextPath}/static/libs/img/approval.gif`,
          url: '/reports/riskControlModel/list',
          label: this.appRateTitle,
          count: 0,
          animate: false,
          activeList: ['/reports/riskControlModel/list', '/reports/riskControlModel/edit', '/reports/riskControlModel/add', '/reports/riskControlModel/show']
        },
        {
          icon: 'icon iconfont ic_detail',
          gif: `/${this.appBackendContextPath}/static/libs/img/path.gif`,
          url: '/reports/riskControl/rank',
          label: '统计分析',
          count: 0,
          animate: false,
          activeList: ['/reports/riskControl/rank']
        },
        {
          icon: 'icon iconfont ic_project',
          gif: `/${this.appBackendContextPath}/static/libs/img/task.gif`,
          url: '/reports/riskControl/trends',
          label: '行业趋势',
          count: 0,
          animate: false,
          activeList: ['/reports/riskControl/trends']
        },
        {
          icon: 'icon iconfont ic_message',
          gif: `/${this.appBackendContextPath}/static/libs/img/message.gif`,
          url: '/msg/list',
          label: '消息关联',
          count: 0,
          animate: false,
          activeList: ['/msg/list']
        },
        {
          icon: 'icon iconfont ic_control',
          gif: `/${this.appBackendContextPath}/static/libs/img/control.gif`,
          url: '/reports/riskControlStandard/edit',
          label: '绩效标准管理',
          count: 0,
          animate: false,
          activeList: ['/reports/riskControlStandard/edit']
        },
        {
          icon: 'icon iconfont ic_control',
          gif: `/${this.appBackendContextPath}/static/libs/img/control.gif`,
          url: '/reports/riskControlWarn/list',
          label: '风险预警',
          count: 0,
          animate: false,
          activeList: ['/reports/riskControlWarn/list', '/reports/riskControlWarn/show']
        },
        {
          icon: 'icon iconfont ic_control',
          gif: `/${this.appBackendContextPath}/static/libs/img/control.gif`,
          url: '/reports/riskControlOrg/list',
          label: '预警设置',
          count: 0,
          animate: false,
          activeList: ['/reports/riskControlOrg/list']
        }
      ]

      if (this.userUserInfo.umsUser.roleType === 0) {
        // this.asideList = [list[0], list[1], list[2], list[3], list[4], list[5]]
        if (this.appPlatform === 'zzwl') {
          this.asideList = [list[19]]
        } else if (this.appPlatform === 'gx') {
          this.asideList = [list[19], list[20], list[24], list[26], list[25], list[4]]
        } else if (this.appPlatform === 'zr' || this.appPlatform === 'zy') {
          this.asideList = [list[11], list[18], list[0], list[1], list[2], list[3], list[4], list[5]]
        } else {
          this.asideList = [list[11], list[0], list[1], list[2], list[3], list[4], list[5]]
        }
      } else if (this.userUserInfo.umsUser.roleType === 1) {
        // this.asideList = [list[0], list[1], list[2], list[3], list[4], list[5]]
        if (this.appPlatform === 'zzwl') {
          this.asideList = [list[20], list[21], list[22]]
        } else if (this.appPlatform === 'gx') {
          this.asideList = [list[20]]
        } else if (this.appPlatform === 'zr' || this.appPlatform === 'zy') {
          this.asideList = [list[11], list[18], list[0], list[1], list[2], list[3], list[4], list[5]]
        } else {
          this.asideList = [list[11], list[0], list[1], list[2], list[3], list[4], list[5]]
        }
      } else if (this.userUserInfo.umsUser.roleType === 2) {
        if (this.appPlatform === 'zzwl') {
          this.asideList = [list[20], list[21], list[22]]
        } else if (this.appPlatform === 'gx') {
          this.asideList = [list[20]]
        } else if (this.appPlatform === 'zr') {
          this.asideList = [list[0], list[1], list[2], list[3], list[5]]
        } else {
          this.asideList = [list[0], list[1], list[2], list[3], list[5]]
        }
        // this.asideList = [list[11], list[0], list[1], list[2], list[3], list[5]]
      } else if (this.userUserInfo.umsUser.roleType === 3) {
        this.asideList = [list[2]]
      } else if (this.userUserInfo.umsUser.roleType === 99) {
        if (this.appPlatform === 'zzwl') {
          this.asideList = [list[6], list[9]]
        } else if (this.appPlatform === 'gx') {
          this.asideList = [list[6], list[9]]
        } else if (this.appPlatform === 'zy') {
          this.asideList = [list[6], list[7], list[12], list[8], list[4], list[9], list[23]]
        } else {
          this.asideList = [list[6], list[7], list[12], list[8], list[4], list[9]]
        }
        // this.asideList = [list[6], list[17], list[7], list[12], list[10], list[8], list[4], list[13], list[9], list[14], list[15], list[16]]
      }
      if (this.appPlatform === 'gx' && this.userUserInfo.roles) {
        this.asideList = []

        if (this.userUserInfo.roles.includes('op-risk-control-template')) {
          this.asideList.push(list[19])
        }
        if (this.userUserInfo.roles.includes('op-risk-control-standard')) {
          this.asideList.push(list[24])
        }
        if (this.userUserInfo.roles.includes('op-risk-control-model')) {
          this.asideList.push(list[20])
        }

        if (this.userUserInfo.roles.includes('op-risk-control-org-list')) {
          this.asideList.push(list[26])
        }
        // if (this.userUserInfo.roles.includes('op-risk-control-warn')) {
        //   this.asideList.push(list[25])
        // }
        this.asideList.push(list[25])
        if (this.userUserInfo.roles.includes('op-risk-control-log')) {
          this.asideList.push(list[4])
        }
      }
      this.$store.dispatch('user/setAsideList', this.asideList)
    },
    handlersetCollapse(collapse) {
      this.$store.dispatch('app/setSidebar', { collapse: collapse })
    }
  }
}
</script>
<style lang="scss">
.aside {
  background: #f2f3f5;
  transition: all 0.25s;
  position: relative;
  padding-right: 12px;
  &__main {
    height: 100%;
  }
  &-open,
  &-close {
    width: 22px;
    height: 120px;
    background: #ffffff;
    border: 1px solid rgba(242, 243, 245, 1);
    box-shadow: -4px 0px 4px 0px rgba(23, 23, 26, 0.1);
    border-radius: 8px 0px 0px 8px;
    position: absolute;
    top: 50%;
    margin-top: -60px;
    right: 0px;
    border-right: 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: #d0d3d6;
    }
    &:hover {
      i {
        color: #3370ff;
      }
    }
  }
  &__badge {
    background-color: #db0011;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    text-align: center;
    white-space: nowrap;

    position: absolute;
    right: 12px;
    top: 17px;
    min-width: 10px;
  }
  &-menu {
    background: #f2f3f5;
    height: 100%;
    overflow: auto;
    &__gif {
      width: 20px;
      margin-right: 7px;
      margin-left: -1px;
    }
  }
  .el-menu {
    border-right: 0px;
    background: #f2f3f5;
  }
  .el-menu-item {
    height: 52px;
    line-height: 52px;
    background: #f2f3f5;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    i {
      margin-right: 8px;
      color: #646a73;
      font-size: 18px;
    }
    &.is-active {
      background: #e3e5e7;
    }
    &:hover {
      background-color: #eaeaea;
    }
    .el-tooltip {
      display: flex !important;
      align-items: center;
    }
  }
}
</style>
