import axios from '@/utils/axios'

/**
 * 根据key获取字典列表
 * @param {*} params
 */
export function dictionarySelectDictionaryListByKey (params) {
  return axios({
    url: '/dictionary/selectDictionaryListByKey',
    method: 'get',
    params
  })
}
/**
 * 添加字典列表
 * @param {*} data
 */
export function dictionaryUpdateDictionary (data) {
  return axios({
    url: '/dictionary/updateDictionary',
    method: 'post',
    data
  })
}

/**
 * 加密
 * @param {*} data
 */
export function dictionaryDataEncryption (data) {
  return axios({
    url: '/dictionary/dataEncryption',
    method: 'post',
    data
  })
}
/**
 * 解密
 * @param {*} data
 */
export function dictionaryDataDecryption (data) {
  return axios({
    url: '/dictionary/dataDecryption',
    method: 'post',
    data
  })
}

/**
 * 添加字典列表
 * @param {*} params
 */
export function addTitle (params) {
  return axios({
    url: '/dictionary/addDictionary',
    method: 'post',
    params
  })
}
