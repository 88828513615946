<template>
  <el-dialog class="reportDialog" v-bind="$attrs" v-on="$listeners" :title="$attrs.dialogTitle">
    <slot />
    <span slot="footer" class="dialog-footer">
      <slot name="footer" />
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'ReportDialog'
}
</script>
<style lang="scss">
.reportDialog {
  .el-dialog {
    border-radius: 8px;
    .el-dialog__title {
      font-size: 18px;
      color: #1f2329;
      font-weight: 500;
    }
    .el-dialog__headerbtn .el-dialog__close {
      font-size: 24px;
      color: #646a73;
      font-weight: 600;
    }
    .el-dialog__body{
      padding-bottom: 24px;
    }
    .el-dialog__footer{
      padding-top: 0px;
    }
  }
}
</style>
